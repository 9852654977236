import {useNavigate} from "react-router-dom";
import {Button, Grid} from "@mui/material";
import React from "react";
import axios from "axios";

const EditUserButton = () => {
    const navigate = useNavigate();
    return (
        <Button
            onClick={() => {
                axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/users/refresh/`, { withCredentials: true })
                // navigate("editProfile");
            }}
            className={
                "border px-7 py-5 bg-transparent rounded-[14px] border-solid border-yellowPrimary create-camp"
            }
        >
            <Grid
                container
                spacing={1}
                flexDirection={"row"}
                alignItems={"center"}
                className={""}
            >
                <Grid item>
                    <img src={"/icons/edit_profile.svg"} alt={"editProfile"} />
                </Grid>
                <Grid item>
                    <p
                        className={
                            "text-white leading-4 normal-case font-raleway text-lg mx-4 my-2"
                        }
                    >
                        Edit profile
                    </p>
                </Grid>
            </Grid>
        </Button>
    );
};

export default EditUserButton;
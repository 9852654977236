import {Button, CircularProgress, Grid, InputAdornment, Skeleton, TextField} from "@mui/material";
import '../App.css'
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import {useState} from "react";
import LoginButton from "../components/LoginButton/LoginButton";
import {Camp} from "../shared/types";
import {Link} from "react-router-dom";

export const getTelegramAuth = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/login-code/`, {
        headers: {
            'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', // Not always effective, depends on the server configuration
        }
    })
}
export const getCamps = () => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/camps/`)
}

const CampCard = ({id, name, image, memberCount}: {id: string, name: string; image: string; memberCount: number; }) => {
    return <Link to={`/camp/${id}`}><Grid container flexDirection={'column'} alignItems={'center'}
                 className='team hover:border-yellowPrimary py-6 px-12 rounded-[19px] cursor-pointer
                 border border-transparent border-solid w-[216px] h-[216px]'>
        <img src={image} alt={image} className={'rounded-full mb-2 h-[80px] w-[80px]'}/>
        <h3 className={'mg-2 text-base text-white'}>{name}</h3>
        <p className={'text-yellowPrimary'}>{memberCount}</p>
        <p className={'text-[#FFFFFF7A] text-base'}>members</p>
    </Grid></Link>
}
const Home = () => {
    const [search, setSearch] = useState<string>('');

    const {data, isLoading: isLoadingCamps} = useQuery({
        queryKey: ['camps'], queryFn: () => getCamps(), refetchOnMount: false
    })
    const {data: camps} = data || {};

    const {data: loginDat, isLoading} = useQuery({
        queryKey: ['login-code'],
        queryFn: () => axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/login-code/`),
        enabled: false

    })

    const {data: loginData} = loginDat || {};
    const {tgLoginQr} = loginData || {};

    return <Grid container flexDirection={'column'} alignItems={'center'} spacing={4}
                 className={'mb-16'}>
        <Grid item xs={10} lg={8}>
            <img src={'/icons/landing.svg'} alt={'lending'}/>
        </Grid>
        <Grid item xs={6} lg={6} className={'w-full'}>
            <Grid container flexDirection={'row'} justifyContent={'flex-end'}>
                <Grid item xs={3} className={'content-center'}>
                    <img src={'/icons/camps.svg'} alt={'camps'}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        sx={{input: {color: 'white'}}}
                        color={'secondary'}
                        onChange={(e) => setSearch(e.target.value)}
                        className={'bg-[#FFFFFF08] text-white p-1 rounded-[20px] border-none'}
                        placeholder={'Search'}
                        size={'small'}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <SearchIcon className={'float-left text-white'}/>
                            </InputAdornment>),
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item className={'lg:w-[50%] md:w-[75%] sm:w-[100%]'}>
            <Grid container flexDirection={'row'} spacing={2} justifyContent={'center'}>
                {!isLoadingCamps ? camps?.filter((camp: Camp) => camp.name.toLowerCase().startsWith(search.toLowerCase())).map((camp: Camp) => {
                    return <Grid item key={camp.id}><CampCard {...camp} /></Grid>
                }) : <>
                    <Grid item>
                        <Skeleton sx={{bgcolor: 'grey.800'}} className={'rounded-[19px]'} variant="rectangular" width={216}
                                  height={216}/></Grid>
                    <Grid item>
                        <Skeleton sx={{bgcolor: 'grey.800'}} className={'rounded-[19px]'} variant="rectangular" width={216}
                                  height={216}/></Grid>
                    <Grid item>
                        <Skeleton sx={{bgcolor: 'grey.800'}} className={'rounded-[19px]'} variant="rectangular" width={216}
                                  height={216}/></Grid>
                </>}
            </Grid>
        </Grid>

        <Grid item xs={10} lg={8}><img src={'/icons/diagram.svg'} alt={'diagram'}/></Grid>
        <Grid container justifyContent={'center'} flexDirection={'row'}>
            <Grid item>
                <div className={'bg-black phone px-4 py-14 border border-[#3A4245] rounded-[30px] relative -z-1'}>
                    <img src={'/icons/activist.svg'} alt={'activist'} className={'-z-50'}/>
                    {isLoading ? <CircularProgress className={'absolute top-[46%] right-[43%] w-[180px] h-[180px]'}/> :
                        <img className={'absolute top-[30%] right-[15%] w-[180px] h-[180px] rounded-lg'}
                             src={`data:image/png;base64,${tgLoginQr}`}
                             alt={'qr'}/>}
                </div>
            </Grid>
            <Grid item className={'ml-20'}>
                <img src={'/icons/join_today.svg'} alt={'join'}/>
                <Grid container flexDirection={'row'} justifyContent={'flex-start'}>
                    <LoginButton />
                    <Button
                        className={"border px-7 py-5 bg-transparent rounded-xl border-solid border-yellowPrimary create-camp"}>
                        <Grid container spacing={1} flexDirection={'row'} className={''}>
                            <Grid item><img src={'/icons/plus.svg'} alt={"plus"}/></Grid>
                            <Grid item><p className={'text-yellowPrimary leading-4 normal-case font-raleway'}>Create Camp
                            </p></Grid>
                        </Grid>
                    </Button>
                </Grid>



                {/*<Grid container flexDirection={'column'} justifyContent={'space-evenly'} className={'h-full'}>*/}
                {/*    <h2>Join Today!</h2>*/}
                {/*    <Grid container flexDirection={'column'}>*/}
                {/*        <ol>*/}
                {/*            <li>Log in securely with our Telegram Bot</li>*/}
                {/*            <li>Find the Camp you want to join or create your camp</li>*/}
                {/*            <li>Apply or gain applications!</li>*/}
                {/*        </ol>*/}
                {/*    </Grid>*/}
                {/*    <Grid container flexDirection={'row'}>*/}
                {/*        <Button*/}
                {/*            className={'normal-case'}*/}
                {/*        ><p className={'normal-case'}>Log in</p></Button>*/}
                {/*        <Button>Create Camp</Button>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Grid>

        </Grid>
    </Grid>
}

export default Home;
import {Patch, PatchType} from "../../shared/types";
import {Grid} from "@mui/material";
import React from "react";
import EarlyAdopter from "../Icons/EarlyAdopter";

const PatchCard = ({ patchType }: Patch) => {
    const patches: PatchType = {
        early_adopter: {description: "Every new user gets it", name: "Early adopter"},
    };
    const {name, description} = patches[patchType] || {};
    return (
        <Grid container flexDirection={'column'} className={'border p-6 bg-transparent rounded-[28px] border-solid border-yellowPrimary create-camp'} alignItems={'center'}>
            {/*<img*/}
            {/*    className={"rounded-full active-patch mb-4"}*/}
            {/*    src={`/icons/${patchType}.svg`}*/}
            {/*    alt={patchType}*/}
            {/*    height={54}*/}
            {/*    width={32}*/}
            {/*    style={{ color: '#331231' }}*/}
            {/*/>*/}
            <EarlyAdopter isActive={true} />
            <p className={'text-white text-center capitalize font-bold text-[18px] my-2'}>{name}</p>
            <p className={'text-white text-center mb-2'}>{description}</p>
        </Grid>
    );
};

export default PatchCard;
import React, {useContext, useEffect, useState} from "react";
import { Grid, Skeleton } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchMe } from "../Header/Header";
import { AuthContext } from "../../AuthProvider";
import { useApiCallWrapper } from "../../hooks/useApiCallWrapper";
import axios from "axios";
import { getCamp } from "../../pages/Camp";

const ParamsLinks = (loc: string[]) => {
  const { userId, campId } = useParams();
  const { token } = useContext(AuthContext);
  const { apiCallWrapper } = useApiCallWrapper();
  const [name, setName] = useState()

  const { data: dataCamp, isLoading: isLoadingCapm } = useQuery({
    queryKey: ["camp", campId],
    queryFn: () => getCamp(campId!),
    refetchOnMount: false, // default: true
  });

  const { data: dataUser, isLoading } = useQuery({
    queryKey: ["me"],
    queryFn: () => apiCallWrapper(() => fetchMe(token)),
    refetchOnMount: false, // default: true
  });

  const { data: userData } = dataUser || {};
  const { telegramId, fullName } = userData || {};
  const { data: campData } = dataCamp || {};
  const {name: campName} = campData || {}

  useEffect(() => {
    const newName = userId ? fullName : campName
    setName(newName)
  }, [userId, campId, fullName, campName]);

  return <p className={"text-[#FFFFFF99]"}>{name}</p>
}

const Breadcrumbs = () => {
  const { userId, campId } = useParams();

  const location = useLocation();
  const loc = location.pathname.split("/");
  const editProfile = loc[loc.length - 1] === "editProfile";
  const { isLoading: isLoadingCamp } = useQuery({
    queryKey: ["camp", campId],
    queryFn: () => getCamp(campId!),
    refetchOnMount: false, // default: true
    enabled: false
  })
  if (!(userId || campId)) {
    return null;
  }

  console.log(isLoadingCamp)
  return (
    <Grid container className={"w-auto"} spacing={2} alignItems={"center"}>
      <Grid item>
        <Link to={"/"}>
          <p className={"text-[#FFFFFF99] underline"}>Main</p>
        </Link>
      </Grid>
      <Grid item>
        <Grid container flexDirection={'row'}>
        <p className={"text-[#FFFFFF99] mr-4"}>-</p>
          {isLoadingCamp ? (
              <Skeleton sx={{bgcolor: 'grey.800'}} className={'rounded-[19px]'} variant="rectangular" width={150}
                        height={16}/>
        ) : <ParamsLinks {...loc}/>}
      </Grid>
      </Grid>
      {editProfile ? (
        <Grid item>
          <Grid container flexDirection={"row"}>
            <p className={"text-[#FFFFFF99] mr-4"}>-</p>
            <p className={"text-[#FFFFFF99]"}>Edit profile</p>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Breadcrumbs;

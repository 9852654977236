import {Button, CircularProgress, Grid} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { AuthContext } from "../../AuthProvider";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { User } from "../../shared/types";
import Notification from '../Notifiaction/Notification';
import {useApiCallWrapper} from "../../hooks/useApiCallWrapper";
const baseURL =
    process.env.NEXT_PUBLIC_BASE_URL || `${process.env.REACT_APP_BASE_URL}api/v1`;

const customAxios = axios.create({
  baseURL: baseURL,
});


export const fetchFullMe = async (
  bearer: string,
): Promise<AxiosResponse<User> | undefined> => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/full-me/`, {
    headers: {
      "Content-Type": "application/json",
      Gcauth: `Bearer ${bearer}`,
    },
  });
};

export const fetchMe = async (bearer: string): Promise<AxiosResponse<User> | undefined> => {
  if(!bearer) return
  return await customAxios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/me/`, {
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Gcauth: `Bearer ${bearer}`,
    },
  });
};

const Header = () => {
  const [open, setOpen] = useState(false);
  const { data, isLoading: isLoadingLoginCode } = useQuery({
    queryKey: ["login-code"],
    queryFn: () => axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/login-code/`),
  });
  const { token } = useContext(AuthContext);

  const { data: loginData } = data || {};
  const { tgLoginUrl } = loginData || {};
  const {apiCallWrapper} = useApiCallWrapper()
  const { data: dataMe } = useQuery({
    queryKey: ["me", token],
    queryFn: () => apiCallWrapper(() => fetchMe(token)),
    refetchOnMount: false,
  });
  //
  const { data: userData } = dataMe || {};
  const { telegramId, image, id } = userData || {};

  return (
    <Grid
      container
      justifyContent={"center"}
      className={"p-4 h-40"}
      alignItems={"center"}
    >
      <Grid item className={"fixed left-5 top-5"}>
        {dataMe ? (
          <Link to={`/user/${id}`}>
            <Button
              disabled={isLoadingLoginCode}
              className={
                "mb-4 hover:border-yellowPrimary border border-transparent border-solid color-white p-7 bg-[#ffffff08] mr-4 hover:bg-[#FFFFFF12] rounded-[28px] h-full"
              }
            >
              {isLoadingLoginCode ? (
                <CircularProgress />
              ) : (
                <Grid
                  container
                  spacing={1}
                  flexDirection={"column"}
                  className={""}
                  alignItems={"center"}
                >
                  <Grid item>
                    <img
                      src={image ? image : "/icons/username.svg"}
                      alt={"profile"}
                    />
                  </Grid>
                </Grid>
              )}
            </Button>
            <p className={"normal-case text-yellowPrimary"}>id: {telegramId}</p>
          </Link>
        ) : (
          <>
            <Button
              onClick={() => window.open(tgLoginUrl)}
              disabled={isLoadingLoginCode}
              className={
                "z-10 hover:border-yellowPrimary border border-transparent border-solid color-white px-7 py-5 bg-[#ffffff08] mr-4 hover:bg-[#FFFFFF12] rounded-[28px] h-full"
              }
            >
              <Grid
                container
                spacing={1}
                flexDirection={"column"}
                className={""}
                alignItems={"center"}
              >
                <Grid item>
                  <img src={"/icons/profile.svg"} alt={"profile"} />
                </Grid>
                <Grid item>
                  <p className={"normal-case text-yellowPrimary"}>Log in</p>
                </Grid>
              </Grid>
            </Button>
            <Button
              className={
                "border px-7 py-5 bg-transparent rounded-[28px] border-solid border-yellowPrimary create-camp"
              }
            >
              <Grid
                container
                spacing={1}
                flexDirection={"column"}
                alignItems={"center"}
                className={""}
              >
                <Grid item>
                  <img src={"/icons/plus.svg"} alt={"plus"} />
                </Grid>
                <Grid item>
                  <p
                    className={
                      "text-yellowPrimary leading-4 normal-case font-raleway"
                    }
                  >
                    Create <br /> Camp
                  </p>
                </Grid>
              </Grid>
            </Button>
          </>
        )}
      </Grid>
      <Grid item className={"h-full"}>
        <Grid
          container
          className={"h-full"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
        >
          <img src={"/icons/logo.svg"} alt={"logo"} />
        </Grid>
      </Grid>
      <Grid item xs={5} className={"fixed right-5 top-5"}>
        <Button
          onClick={() => setOpen(!open)}
          className={
            "bg-[#ffffff07] hover:bg-[#FFFFFF12] rounded-[14px] h-[50px] w-full cursor-pointer px-3"
          }
        >
          <Grid
            container
            spacing={2}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <img src={"/icons/alarm.svg"} alt={"alarm"} />
            </Grid>
            <Grid item>
              <p className={"text-white normal-case"}>Notification bar</p>
            </Grid>
            <Grid item className={"mr-1"}>
              <KeyboardArrowDownIcon className={"text-white"} />
            </Grid>
          </Grid>
        </Button>
        <Grid
          container
          flexDirection={"column"}
          className={"absolute w-full"}
          spacing={4}
        >
          {open &&
            [
              { name: "dsadsa dsadsa", action: "dsadas dsadas" },
              {
                name: "wwwdsada swwwwds adasw",
                action: "dsadcccc ccccccccds adccccccc ccccc",
              },
              {
                name: "dsa ddsad",
                action: "dwsc xzcxzc xdwscxzc xzcx",
              },
            ].map((e, index) => {
              return (
                <Grid key={index} item>
                  <Notification name={e.name} action={e.action} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;

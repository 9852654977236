import { Grid } from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { ReactNode } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const Layout = ({ page }: { page: ReactNode }) => {
  return (
    <div className={"root1"}>
      <div className={"root"}>
        <Header />
        <Grid container justifyContent={"center"} className={'mb-16'}>
          <Breadcrumbs />
        </Grid>
        {page}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;

import {
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import { useQueryUrlState } from "../../hooks/useQueryUrlState";
import { Achievement, Membership } from "../../shared/types";
import BordMember from "../BordMember";
import {useParams} from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#F5A851",
  },
}));

enum AchievementNames {
  full_member = "full member",
  hype_surfer = "hype surfer",
  samgha_agent = "samgha agent",
  career_maker = "career maker",
  active_organizer = "active organizer",
}

const UserCamps = ({
  achievements,
  membership,
}: {
  achievements: Achievement[];
  membership: Membership[];
}) => {
  // const { achievementType } = achievements || {};
  const [membershipQueryUrl = "0", setMembershipQueryUrl] =
    useQueryUrlState("membership");
  const { camp } = membership[Number(membershipQueryUrl) || 0] || {};
  const { name, image, id: campId } = camp || {};
  const { small} = image || {};
  const achievementsForCamp = achievements?.map((e) => e.achievementType);
  const changeMembership = (direction: boolean) => {
    if (direction) {
      if (Number(membershipQueryUrl) === membership.length - 1) {
        setMembershipQueryUrl("0");
      } else {
        setMembershipQueryUrl(String(Number(membershipQueryUrl) + 1));
      }
    } else {
      if (membershipQueryUrl === "0") {
        setMembershipQueryUrl(String(membership.length - 1));
      } else {
        setMembershipQueryUrl(String(Number(membershipQueryUrl) - 1));
      }
    }
  };
  return (
    <Grid
      container
      className={"bg-[#FFFFFF08] rounded-[19px] p-8"}
      flexDirection={"column"}
    >
      <Grid item xs={12} className={"w-full mb-8"}>
        <Grid
          container
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-around"}
        >
          <Grid item>
            <IconButton
              className={
                "bg-[#FFFFFF08] p-3 hover:border-yellowPrimary border border-transparent border-solid color-white bg-[#ffffff08] mr-4 hover:bg-[#FFFFFF12] rounded-[28px] h-full"
              }
              onClick={() => changeMembership(false)}
            >
              <ArrowBackIosNewIcon className={"text-[#F5A851]"} />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Grid container flexDirection={"row"} justifyContent={"center"}>
              <img
                className={"rounded-full w-[40px] h-[40px]"}
                src={small}
                alt={"profile"}
              />
              <h1 className={"ml-4 text-2xl text-[lightGray]"}>{name}</h1>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              className={
                "bg-[#FFFFFF08] p-3 hover:border-yellowPrimary border border-transparent border-solid color-white bg-[#ffffff08] mr-4 hover:bg-[#FFFFFF12] rounded-[28px] h-full"
              }
              onClick={() => changeMembership(true)}
            >
              <ArrowForwardIosIcon className={"text-[#F5A851]"} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={"mb-8"}>
        <Grid container justifyContent={"center"}>
          <img src={"/icons/samanera.svg"} alt={"samanera"} />
        </Grid>
      </Grid>
      <Grid item xs={8} className={"w-full mb-8"}>
        <BorderLinearProgress variant="determinate" value={50} />
      </Grid>
      <Grid item xs={12}>
        <Grid container flexDirection={"row"} justifyContent={'space-between'}>
          {[...achievements, {achievementType:  "samgha_agent"}, {achievementType: "career_maker"}, {achievementType:"active_organizer"}]?.map(({ achievementType }: any) => (
            <Grid item className={`achievement-card ${achievementsForCamp?.includes(achievementType) ? "" : "inactive"}`}>
              <BordMember
                {...{
                  image: { small: `/icons/${achievementType}.svg` },
                  // @ts-ignore
                  fullName: AchievementNames[achievementType],
                  karma: achievementType,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserCamps;

import React, {createContext, useEffect, useState} from "react";

export const AuthContext = createContext({token: '', saveToken: (userToken: '') => {}, clearToken: () => {}});

export const AuthProvider = ({children}: any) => {
    const [token, setToken] = useState(localStorage.getItem('accessToken') || '');

    // Function to update the token
    const saveToken = (userToken: string) => {
        localStorage.setItem('accessToken', userToken);
        setToken(userToken);
    };

    // Function to clear the token
    const clearToken = () => {
        localStorage.removeItem('accessToken');
        setToken("");
    };

    return (<AuthContext.Provider value={{token, saveToken, clearToken}}>
        {children}
    </AuthContext.Provider>);
};

// Custom hook to use the AuthContext

import {Grid, Link} from "@mui/material";

const Notification = ({name, action}: { name: string, action: string }) => {
    return <Grid container flexDirection={'row'} justifyContent={'flex-start'}
                 className={'p-2  create-camp border-solid border-yellowPrimary border rounded-[19px] w-full mt-4'}>
        <Grid item xs={3}><img src={'icons/ghandhi.svg'} alt={'gma'}/></Grid>
        <Grid item xs={9}><Grid container flexDirection={'column'}>
            <div className={''}>
                <p className={'leading-3 text-xs inline text-white leading-2'}>{name}</p>
                <Link className={'leading-3 inline cursor-pointer text-xs mx-2 leading-2'}>name</Link>
                <p className={'inline leading-3 text-xs text-white leading-2'}>{action}</p>
                <Link className={'inline leading-3	cursor-pointer text-xs mx-2 leading-2'}>action</Link>
            </div>
        </Grid></Grid>
    </Grid>
}

export default Notification;
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AppRouter from "./AppRouter";
import React from 'react';
import {AuthProvider} from "./AuthProvider";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
})

function App() {
    return (<BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <AppRouter/>
                </AuthProvider>
            </QueryClientProvider>
        </BrowserRouter>);
}

export default App;

import {Button, Grid} from "@mui/material";
import '../../App.css'
import LoginButton from "../LoginButton/LoginButton";

const Footer = () => {
    return <Grid container spacing={5}
                 flexDirection={'column'}
                 alignContent={'center'}
                 justifyContent={'center'}
                 className={'bg-[#18171B] mt-10'}
                 alignItems={'center pb-4'}>
        <Grid item>
            <Grid container justifyContent={'center'}>
                <img src={"/icons/logo.svg"} alt={'logo'}/>
            </Grid>
        </Grid>
        <Grid item>
            <Grid container flexDirection={'row'} spacing={14} justifyContent={'center'}>
                <Grid item><p className={'text-[#FFFFFF9E] cursor-pointer'}>Terms of Use</p></Grid>
                <Grid item><p className={'text-[#FFFFFF9E] cursor-pointer'}>Privacy Policy</p></Grid>
                <Grid item><p className={'text-[#FFFFFF9E] cursor-pointer'}>Statement</p></Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Grid container flexDirection={'row'} justifyContent={'center'}>
                <LoginButton />
                <Button className={"bg-red px-10 py-4 rounded-xl bg-[#FFFFFF08] hover:bg-[#FFFFFF12] border border-transparent border-solid hover:border-yellowPrimary"}>
                    <p className={'text-white normal-case'}>All Camps</p>
                </Button>
            </Grid>
        </Grid>
        <Grid item><img src={'/icons/footer.svg'} alt={'footer'} /></Grid>
    </Grid>
}

export default Footer;
import {Voting as VotingType} from "../shared/types";
import {Grid} from "@mui/material";
import moment from "moment";
import React from "react";

const Voting = ({ createdAt, title, status}: VotingType) => {
    return (
        <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
            className={"bg-[#FFFFFF08] rounded-[19px] p-4"}
            key={createdAt}
        >
            <Grid item xs={8}>
                <Grid container>
                    <Grid item xs={3}>
                        <p className={"text-[#F5A851] text-[20px]"}>
                            {moment(createdAt).subtract(10, "days").calendar()}
                        </p>
                    </Grid>
                    <Grid item>
                        <p className={"text-[20px] text-[lightGray]"}>{title}</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <p className={`capitalize text-[20px] ${status === "ongoing" ? 'text-[#7FE889]' :'text-[#E37373]'}`}>{status}</p>
            </Grid>
        </Grid>
    );
};

export default Voting;
import { useNavigate } from 'react-router-dom';
import axios from "axios";

export function useApiCallWrapper() {
    const navigate = useNavigate();
    const refresh = () => axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/users/refresh/`)

    async function apiCallWrapper<T>(apiCall: () => Promise<T>): Promise<T | null> {
        try {
            const response = await apiCall();
            return response;
        } catch (error: any) {
            if (error.response?.status === 401) {
                // Redirect to the home screen if 401 Unauthorized
                await refresh();
                localStorage.clear();
                navigate('/');
            }
            return null;
        }
    }

    return { apiCallWrapper };
}
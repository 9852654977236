import {
  Button, CircularProgress,
  Grid,
  styled,
  TextField,
} from "@mui/material";
import {useMutation, useQuery} from "@tanstack/react-query";
import { fetchFullMe } from "../components/Header/Header";
import React, { useContext } from "react";
import { AuthContext } from "../AuthProvider";
import { useApiCallWrapper } from "../hooks/useApiCallWrapper";
import { useForm } from "react-hook-form";
import '../App.css'
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px', // Customize the border radius
    backgroundColor: "#FFFFFF08"
  },
});

const EditUser = () => {
  const { token } = useContext(AuthContext);
  const { apiCallWrapper } = useApiCallWrapper();
  const {userId} = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["full-me"],
    queryFn: () => apiCallWrapper(() => fetchFullMe(token)),
    // onSuccess: () => console.log('dsadas')
  });
  const { data: dataMe } = data || {};

  const {
    fullName,
    username: originalUsername,
    // gold,
    // achievements = [],
    // membership,
    // patches,
    bio: originalBio,
  } = dataMe || {};
  const {
    getValues,
    formState: { errors },
    register,
    handleSubmit,
      reset
  } = useForm({
    defaultValues: {
      name: fullName,
      username: originalUsername,
      bio: originalBio,
    },
  });
  const { name, username, bio } = getValues();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: (data) => {
      const bearer = localStorage.getItem('accessToken');
      return axios.patch(`${process.env.REACT_APP_BASE_URL}api/v1/users/me/`, data,{
        headers: {
          "Content-Type": "application/json",
          Gcauth: `Bearer ${bearer}`,
        },
      })
    },
  })

  const onSubmit = (data: any) => mutation.mutate(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container flexDirection={"column"} alignContent={"center"}>
        <Grid item className={"w-[40%] mb-8"}>
          <h1 className={"text-[lightGray] mb-4 text-center text-[56px]"}>
            Edit profile
          </h1>
        </Grid>
        <Grid item className={"w-[40%] mb-8"}>
          <p className={"text-[lightGray] mb-4"}>Name</p>
          <CustomTextField
            sx={{ input: { color: "white" } }}
            {...register("name", { required: true })}
            fullWidth
            error={!!errors.name}
            className={"h-12 rounded-xl"}
            defaultValue={name}
          />
        </Grid>
        <Grid item className={"w-[40%] mb-8"}>
          <p className={"text-[lightGray] mb-4"}>Nickname</p>
          <CustomTextField
            sx={{ input: { color: "#FFFFFF" } }}
            {...register("username", { required: true })}
            fullWidth
            error={!!errors.username}
            className={"h-12 rounded-lg"}
            defaultValue={username}
          />
        </Grid>
        <Grid item className={"w-[40%] mb-8"}>
          <p className={"text-[lightGray] mb-4"}>Short bio</p>
          <textarea
            {...register("bio", { required: false })}
            id="bio"
            name="bio"
            className={
              "custom-scrollbar w-full bg-[#FFFFFF08] py-4 px-2 min-h-[160px] rounded-lg text-[lightGray] focus:border-red"
            }
          />
        </Grid>
        <Grid item className={"w-[40%] mb-12"}>
          <p className={"text-[lightGray] mb-4"}>Photo</p>
          <img
            src={"/icons/username.svg"}
            alt={"profile"}
            height={197}
            width={197}
          />
        </Grid>
        <Grid item className={"w-[40%] mb-8 text-center"}>
          <Button
            type={"submit"}
            disabled={mutation.status === "pending"}
            style={{
              borderRadius: "14px",
              background:
                "linear-gradient(334.34deg, #F28331 9.36%, #F5A851 51.58%, #F9C68B 92.31%)",
            }}
            className={"normal-case text-lg px-20 py-4 text-black"}
          >
            {mutation.status !== "pending" ? (
              "Save"
            ) : (
              <CircularProgress color="secondary" />
            )}
          </Button>
          <Link to={`/user/${userId}`}>
            <Button
              type={"submit"}
              disabled={mutation.status === "pending"}
              style={{
                borderRadius: "14px",
                border: "1px #F28331 solid",
              }}
              className={"normal-case text-lg px-20 py-4 text-[lightGray] ml-2"}
            >
              Cancel
            </Button>
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditUser;

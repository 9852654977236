import { Gallery as GalleryType } from "../shared/types";
import { useQueryUrlState } from "../hooks/useQueryUrlState";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";

const Gallery = ({ gallery }: { gallery: GalleryType[] }) => {
  const [galleryQueryUrl, setGalleryQueryUrl] = useQueryUrlState("gallery");
  const {
    image: { large },
  } = gallery[Number(galleryQueryUrl || 0)];
  const changeMembership = (direction: boolean) => {
      console.log(Number(galleryQueryUrl  || 0))
    if (direction) {
      if (Number(galleryQueryUrl || 0) === gallery.length - 1) {
        setGalleryQueryUrl("0");
      } else {
        setGalleryQueryUrl(String(Number(galleryQueryUrl || 0) + 1));
      }
    } else {
      if (!galleryQueryUrl || galleryQueryUrl === "0") {
        setGalleryQueryUrl(String(gallery.length - 1));
      } else {
        setGalleryQueryUrl(String(Number(galleryQueryUrl || 0) - 1));
      }
    }
  };
  return (
    <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={'space-between'}>
      <Grid item>
        <IconButton
          className={
            "bg-[#FFFFFF08] p-3 hover:border-yellowPrimary border border-transparent border-solid color-white bg-[#ffffff08] hover:bg-[#FFFFFF12] rounded-[28px] h-full"
          }
          onClick={() => changeMembership(false)}
        >
          <ArrowBackIosNewIcon className={"text-[#F5A851]"} />
        </IconButton>
      </Grid>
      <Grid item xs={10}>
        <img src={large} alt={"gallery"} className={'rounded-[10px]'} />
      </Grid>
      <Grid item>
        <IconButton
          className={
            "bg-[#FFFFFF08] p-3 hover:border-yellowPrimary border border-transparent border-solid color-white bg-[#ffffff08] hover:bg-[#FFFFFF12] rounded-[28px] h-full"
          }
          onClick={() => changeMembership(true)}
        >
          <ArrowForwardIosIcon className={"text-[#F5A851]"} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Gallery;

import {Grid} from "@mui/material";
import React from "react";

const UserProfile = ({
                         fullName = "",
                         username = "",
                         bio = "",
                         gold = 0,
                     }: {
    fullName?: string;
    username?: string;
    bio?: string;
    gold?: number;
}) => {
    return (
        <Grid container flexDirection={"column"} spacing={4}>
            <Grid
                container
                flexDirection={"row"}
                justifyContent={"center"}
                spacing={4}
            >
                <Grid item>
                    <img
                        src={"/icons/username.svg"}
                        alt={"profile"}
                        height={197}
                        width={197}
                    />
                </Grid>
                <Grid item>
                    <Grid
                        container
                        flexDirection={"column"}
                        spacing={3}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <h1 className={"text-white text-[44px]"}>{fullName}</h1>
                        </Grid>
                        <Grid item>
                            <Grid container flexDirection={"row"} alignItems={"center"}>
                                <p className={"text-lg mr-4 text-[lightGray]"}>A.K.A.</p>
                                <p className={"text-lg username"}>{username}</p>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container flexDirection={"row"} alignItems={"center"}>
                                <img src={"/icons/avatar.svg"} alt={"profile"} />
                                <p className={"ml-4 text-3xl username"}>{gold}</p>
                            </Grid>
                            <p className={"text-[#837D9B]"}>gandhi coins</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <p className={"text-[lightGray] text-lg"}>
                    {bio}
                </p>
            </Grid>
        </Grid>
    );
};

export default UserProfile;
import {BoardMembership} from "../shared/types";
import {Grid} from "@mui/material";
import React from "react";
import {useParams} from "react-router-dom";

const BordMember = ({ image, fullName, karma }: BoardMembership) => {
    const { small } = image || {};
    const {userId} = useParams();

    return (
      <Grid
        container
        flexDirection={"column"}
        spacing={2}
        alignItems={"center"}
      >
        <Grid item>
          <img
            src={small || "/icons/member.svg"}
            alt={"bord-member"}
            className={"rounded-[50%]"}
          />
        </Grid>
        <Grid item>
          <p className={"text-lightGray text-center uppercase"}>{fullName}</p>
        </Grid>
        <Grid item>
          <Grid container flexDirection={"row"} spacing={2}>
            {!userId && <img src={"/icons/karma.svg"} alt={"karma"} />}
            <p className={"ml-2 text-yellowPrimary"}>{karma}</p>
          </Grid>
        </Grid>
      </Grid>
    );
};

export default BordMember;
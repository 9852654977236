import {Button, Grid, IconButton, Skeleton} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Person2Icon from "@mui/icons-material/Person2";
import { BoardMembership, Voting as VotingType } from "../shared/types";
import _ from "lodash";
import React from "react";
import BordMember from "../components/BordMember";
import Voting from "../components/Voting";
import Gallery from "../components/Gallery";

export const getCamp = (campId: string) => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/camps/${campId}`)
}

const Camp = () => {
  const { campId } = useParams();
  const { data: dataCamp, isLoading } = useQuery({
    queryKey: ["camp", campId],
    queryFn: () => getCamp(campId!)
  });
  const { data } = dataCamp || {};
  const { image, name, memberCount, board, description, votings, members, gallery } = data || {};
  const { small } = image || {};
  return !isLoading ? (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <img
            className={"rounded-[50%] text-center"}
            src={small}
            alt={"camp"}
          />
        </Grid>
          <Grid container justifyContent={"center"}>
              <p className={"text-[58px] text-[lightGray] font-roboto-slab]"}>
                  {name}
              </p>
          </Grid>
          <Grid container justifyContent={"center"}>
              <Grid container alignItems={"center"} flexDirection={"column"}>
                  <p className={"text-[#F5A851] text-[26px] leading-[26px] m-0"}>{memberCount}</p>
                  <p className={"text-[#FFFFFF7A] text-[12px] m-0"}>members</p>
              </Grid>
          </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={4}>
            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems={"center"}
                  flexDirection={"row"}
                  spacing={3}
                >
                  <Grid item xs={6}>
                    <Button
                      className={
                        "border w-full text-[16px] font-medium px-7 py-3 bg-transparent rounded-[14px] border-solid border-yellowPrimary normal-case text-[lightGray] create-camp"
                      }
                    >
                      <img
                        className={"mr-3"}
                        src={"/icons/new_voting.svg"}
                        alt={"new_voting"}
                      />
                      New voting
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={
                        "border w-full text-[16px] font-medium px-7 py-3 bg-transparent rounded-[14px] border-solid border-yellowPrimary normal-case text-[lightGray] create-camp"
                      }
                    >
                      <NotificationsIcon
                        className={"mr-2"}
                        sx={{ color: "#F5A851" }}
                      />
                      New notification
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={
                    "border w-full text-[16px] font-medium px-7 py-3 bg-transparent rounded-[14px] border-solid border-yellowPrimary normal-case text-[lightGray] create-camp"
                  }
                >
                  <Person2Icon className={"mr-2"} sx={{ color: "#F5A851" }} />
                  Become a member
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
              <p className={"text-[44px] text-[lightGray] board"}>Board</p>
          </Grid>
          <Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2} justifyContent={'center'}>
              {!_.isEmpty(board) && board?.map((bordMember: BoardMembership, index: number) => {
                  return (
                    <Grid item xs={3} key={index}>
                      <BordMember {...bordMember} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems={"center"} flexDirection={"column"}>
          <p className={"text-[44px] text-[lightGray] board"}>Vision</p>
          <p className={"text-lg text-[lightGray]"}>{description}</p>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={6}>
            <Grid container alignItems={"center"} flexDirection={"column"}>
              <p className={"text-[44px] text-[lightGray] board"}>Last votings</p>
              {votings?.map((v: VotingType, index: number) => <Voting {...v} key={index} />)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
              <p className={"text-[44px] text-[lightGray] board"}>Members</p>
          </Grid><Grid container justifyContent={"center"} spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2} justifyContent={'center'}>
              {!_.isEmpty(members) && members?.map((bordMember: BoardMembership) => {
                  return (
                    <Grid item xs={3}>
                      <BordMember {...bordMember} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {gallery &&
          <Grid item xs={12}>
              <Grid container justifyContent={"center"}>
                  <p className={"text-[44px] text-[lightGray] board"}>Gallery</p>
              </Grid>
              <Grid container justifyContent={"center"}>
                  <Grid item xs={6}>
                      <Gallery gallery={[...gallery,...gallery,...gallery,...gallery,...gallery]} />
                  </Grid>
              </Grid>
          </Grid>
      }
    </Grid>
  ) : (
    <Grid container flexDirection={"column"} spacing={4} alignItems={"center"}>
      <Skeleton
        sx={{ bgcolor: "blue.400" }}
        className={"rounded-[19px]"}
        variant="rectangular"
        width={926}
        height={737}
      />
    </Grid>
  );
};
export default Camp;

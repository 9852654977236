import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchFullMe } from "../components/Header/Header";
import { AuthContext } from "../AuthProvider";
import { Grid, Skeleton } from "@mui/material";
import "../App.css";
import UserCamps from "../components/UserCamp/UserCamp";
import PatchCard from "../components/PatchCard/PatchCard";
import { useApiCallWrapper } from "../hooks/useApiCallWrapper";
import EditUserButton from "../components/EditUserButton";
import UserProfile from "../components/UserProfile";

const User = () => {
  const { token } = useContext(AuthContext);
  const { apiCallWrapper } = useApiCallWrapper();

  const { data, isLoading } = useQuery({
    queryKey: ["full-me"],
    queryFn: () => apiCallWrapper(() => fetchFullMe(token)),
    refetchOnMount: false,
  });
  const { data: dataMe } = data || {};

  const {
    fullName,
    username,
    gold,
    achievements = [],
    membership,
    patches,
    bio,
  } = dataMe || {};

  return isLoading ? (
    <Grid container flexDirection={"column"} spacing={4} alignItems={"center"}>
      <Skeleton
        sx={{ bgcolor: "blue.400" }}
        className={"rounded-[19px]"}
        variant="rectangular"
        width={926}
        height={737}
      />
    </Grid>
  ) : (
    <Grid container flexDirection={"column"} spacing={6} alignItems={"center"}>
      <Grid item xs={6}>
        <UserProfile {...{ username, fullName, gold, bio }} />
      </Grid>
      <Grid item xs={7} className={"w-full"}>
        <Grid container justifyContent={"center"}>
          <EditUserButton />
        </Grid>
      </Grid>
      <Grid item xs={7} className={"w-full"}>
        <UserCamps
          achievements={achievements}
          membership={membership ? [...membership, {camp: {id: '', image: {small: 'https://fra1.digitaloceanspaces.com/gandhi/app/Images/Camps/7tODS2Q5uCFUSqmOTRMDeRN7q.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO008424F8XVCJQYCBDF%2F20240818%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20240818T133647Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=0e49fd60244b97079f17612a8fc1c1bfddb6f9c9e9b67861f15a459f5dd4d089'}, name: "Legalize Belarus"}}] : []}
        />
      </Grid>
      <Grid item xs={7} className={"w-full"}>
        <h1 className={'text-[44px] text-center'}>Patches</h1>
        <p className={'text-[lightGray] text-center'}>Patches are unique awards given for major achievements. Each patch is given only once per user.</p>
        <Grid container flexDirection={'row'}>
          {patches?.map(({ patchType }) => <Grid item xs={2}><PatchCard patchType={patchType} /></Grid>)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default User;

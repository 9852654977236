import {Button} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";

const LoginButton = () => {
    const {data: loginDat} = useQuery({
        queryKey: ['login-code'],
        queryFn: () => axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/login-code/`),
        enabled: false

    })

    const {data: loginData} = loginDat || {};
    const {tgLoginUrl} = loginData || {};
    return <Button onClick={() => window.open(tgLoginUrl)}
                   style={{backgroundColor: "#3290F0"}} className={"bg-red px-10 py-4 rounded-xl mr-3"}>
        <img src={"/icons/send.svg"} alt={'send'}/><p className={'text-white normal-case ml-3'}>Log In</p></Button>
}

export default LoginButton;
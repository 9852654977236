import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import User from "./pages/User";
import {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {AuthContext} from "./AuthProvider";
import EditUser from "./pages/EditUser";
import {fetchFullMe, fetchMe} from "./components/Header/Header";
import {useApiCallWrapper} from "./hooks/useApiCallWrapper";
import _ from "lodash";
import Camp from "./pages/Camp";
const AppRouter = () => {
    const { token, saveToken } = useContext(AuthContext);
    const { apiCallWrapper } = useApiCallWrapper();

    const {data
    } = useQuery({
        queryKey: ['login-code'], queryFn: () => axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/users/login-code/`)
    })

    const { data: fullnme, isLoading, refetch } = useQuery({
        queryKey: ["me", token],
        queryFn: () => apiCallWrapper(() => fetchMe(token)),
        refetchOnMount: false,
    });

    const {data: loginData} = data || {};
    const { code} = loginData || {};

    useEffect(() => {
        const bearer = localStorage.getItem('accessToken');

        if (!bearer && loginData && _.isEmpty(token)) {
            const intervalId = setInterval(async () => {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v1/users/login/`, {'code': code}, {withCredentials: true});
                    const {data, status} = response || {};
                    const {access, refresh} = data || {};
                    // localStorage.setItem('refreshToken', refresh);

                    if (status === 200) {
                        saveToken(access); // Set stopPolling to true
                        clearInterval(intervalId);
                    }
                } catch (error) {
                    console.error('Error during polling:', error);
                }
            }, 3000);

            return () => clearInterval(intervalId);
        }
    }, [loginData, code]);

    return <Routes>
        <Route path='/' element={<Layout page={<Home/>}/>}/>
        <Route path='/camp/:campId' element={<Layout page={<Camp/>}/>}/>
        {token && <Route path='/user/:userId' element={<Layout page={<User/>}/>}/>}
        {token && <Route path='/user/:userId/editProfile' element={<Layout page={<EditUser/>}/>}/>}
    </Routes>
}

export default AppRouter;
